<template>
  <div class="flex flex-col items-center justify-center w-full pt-24">
    <h1 class="my-16 mb-10 text-5xl text-gray-400 dark:text-gray-400">{{ $t('title') }}</h1>

    <svg width="99" height="124" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:src="'@/assets/images/sorry.svg'" v-bind:alt="'Sorry icon'"><g fill="none" fill-rule="evenodd"><path d="M15.107 0H83.73c6.686 0 12.152 5.331 12.32 12.015l2.47 98.488c.17 6.804-5.207 12.458-12.01 12.629-.104.003-.207.004-.31.004H12.637c-6.806 0-12.324-5.518-12.324-12.324 0-.103.001-.206.004-.309l2.47-98.488C2.955 5.33 8.421 0 15.107 0z" fill="#6284D6" fill-rule="nonzero"/><g transform="translate(57 28)" fill="#00292B"><circle cx="1.5" cy="1.5" r="1.5"/><circle cx="19.5" cy="1.5" r="1.5"/></g><path fill="#E8F679" fill-rule="nonzero" d="M17.382 36.788l16.114.844-.845 16.114-16.113-.845z"/><path d="M30.638 53.64a2.017 2.017 0 002.12-1.908l-2.015-.105-.105 2.014z" fill-opacity=".4" fill="#FFF" fill-rule="nonzero"/><path stroke="#9893C0" stroke-width="1.009" style="mix-blend-mode:multiply" stroke-linecap="square" d="M21.623 42.564l8.058.423m-8.216 2.598l6.044.317"/><path stroke="#67D1E1" stroke-width=".5" fill="#67D1E1" fill-rule="nonzero" d="M7.485 58.978l16.097-1.126 1.126 16.097L8.61 75.075z"/><path d="M22.696 74.09a2.017 2.017 0 001.871-2.153l-2.012.141.141 2.012z" fill-opacity=".4" fill="#FFF" fill-rule="nonzero"/><path stroke="#9893C0" stroke-width="1.009" style="mix-blend-mode:multiply" stroke-linecap="square" d="M12.4 64.195l8.049-.563m-7.839 3.58l6.038-.422"/></g></svg>

    <h2
      class="my-8 mb-10 mx-96 text-2xl font-extralight text-gray-400 dark:text-gray-400 text-center"
    >
      {{ $t('message') }}
    </h2>

    <router-link to="/">
      <pill-button>
        {{ $t('go_home') }}
      </pill-button>
    </router-link>
  </div>
</template>

<script>
import componentI18n from '@/i18n/componentI18n';

export default {
  ...componentI18n('views.kicked_out_of_retro_error')
};
</script>
